<template>
  <div class="hero-text">
    <h1 ref="h1" class="text-primary">{{title}}</h1>
    <h2 ref="h2" class="text-secondary">{{subtitle}}</h2>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import WaitLoader from '@/mixins/wait-loader'

export default {
  name: 'HeroText',
  mixins: [WaitLoader()],
  props: {
    title: { type: String },
    subtitle: { type: String }
  },
  async mounted () {
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.inTl && this.inTl.kill()
    this.tl && this.tl.kill()
  },
  watch: {
    '$root.preloader.completedOut': {
      immediate: true,
      handler () {
        this.playAnimation()
      }
    },
    '$root.userIsLogged': {
      immediate: true,
      handler () {
        this.playAnimation()
      }
    }
  },
  methods: {
    createTl () {
      this.inTl = gsap.timeline({ paused: true })
      this.inTl.fromTo(this.$refs.h1, { opacity: 0, xPercent: -60 }, { opacity: 1, xPercent: -40, duration: 1 })
      this.inTl.fromTo(this.$refs.h2, { opacity: 0, xPercent: 80 }, { opacity: 1, xPercent: 53, duration: 1 }, '<')

      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top top',
          end: 'bottom top',
          scrub: 1
        }
      })

      this.checkLoaderState()
      this.tl.to(this.$refs.h1, { xPercent: -10, duration: 1, overwrite: 'auto', immediateRender: false })
      this.tl.to(this.$refs.h2, { xPercent: 30, duration: 1, overwrite: 'auto', immediateRender: false }, '<')
      this.playAnimation()
    },
    playAnimation () {
      if (this.inTl && this.canStartAnimations) {
        this.inTl.play()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-text {
  position: relative;

  overflow: hidden;

  padding-top: rem(70px);

  margin-bottom: rem(24px);
  @screen md {
    padding-top: rem(90px);

    margin-bottom: rem(40px);
  }
}

h1,
h2 {
  display: block;

  line-height: 1;
  white-space: nowrap;
  will-change: transform;
}

h2 {
  margin-top: rem(-74px * 0.75);
  @screen md {
    margin-top: rem(-259px * 0.75);
  }
}
</style>
