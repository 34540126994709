export default (extraDelay) => {
  return {
    beforeDestroy () {
      if (this.delayTm) {
        clearTimeout(this.delayTm)
      }
    },
    watch: {
      '$root.preloader.completedOut': {
        immediate: true,
        handler () {
          this.onPageVisible()
        }
      },
      '$root.userIsLogged': {
        immediate: true,
        handler () {
          this.onPageVisible()
        }
      }
    },
    computed: {
      canStartAnimations () {
        return this.$root.preloader.completedOut && (!this.$root.Settings.forceLogin || this.$root.userIsLogged)
      }
    },
    methods: {
      checkLoaderState () {
        if (!this.canStartAnimations) {
          this.switchTL('disable')
        }
      },
      switchTL (action) {
        if (!this.tl) return
        if (Array.isArray(this.tl)) {
          this.tl.forEach(tl => {
            tl.scrollTrigger[action](false)
          })
        } else {
          this.tl.scrollTrigger[action](false)
        }
      },
      onPageVisible () {
        if (!this.tl || !this.canStartAnimations) return

        if (extraDelay) {
          this.delayTm = setTimeout(() => {
            this.switchTL('enable')
          }, extraDelay)
        } else {
          this.switchTL('enable')
        }
      }
    }
  }
}
